import { IIcon } from '../designSystem/components/i';
import { TranslateFn } from '../services/translationService';
import { assertUnreachable } from '../util/assertUnreachable';

export enum AccreditationsRendering {
    List = 'List',
    SlotsList = 'SlotsList'
}

export enum PositionsRendering {
    Calendar = 'Calendar',
    List = 'List',
    Map = 'Map',
    SlotsList = 'SlotsList'
}

export enum VolunteersRendering {
    AccreditationsMatrix = 'AccreditationsMatrix',
    Calendar = 'Calendar',
    List = 'List'
}

export type AllRendering = AccreditationsRendering | PositionsRendering | VolunteersRendering;

export function renderingIcon(rendering: AllRendering): IIcon {
    switch (rendering) {
        case VolunteersRendering.AccreditationsMatrix:
            return 'table-cells';
        case PositionsRendering.Calendar:
        case VolunteersRendering.Calendar:
            return 'calendar';
        case AccreditationsRendering.List:
        case PositionsRendering.List:
        case VolunteersRendering.List:
            return 'table';
        case PositionsRendering.Map:
            return 'map-location';
        case AccreditationsRendering.SlotsList:
        case PositionsRendering.SlotsList:
            return 'table';
        default:
            return assertUnreachable(rendering);
    }
}

export function renderingTranslation(t: TranslateFn, rendering: AllRendering) {
    switch (rendering) {
        case VolunteersRendering.AccreditationsMatrix:
            return t('matrice_des_acc_84939');
        case PositionsRendering.Calendar:
        case VolunteersRendering.Calendar:
            return t('vue_planning_91663');
        case AccreditationsRendering.List:
        case PositionsRendering.List:
        case VolunteersRendering.List:
            return t('vue_liste_56882');
        case PositionsRendering.Map:
            return t('vue_carte_79064');
        case AccreditationsRendering.SlotsList:
        case PositionsRendering.SlotsList:
            return t('vue_liste_cr_n_47406');
        default:
            return assertUnreachable(rendering);
    }
}
