import { injectable } from 'inversify';
import {
    EventsSortAttributes,
    EventState,
    FilterType,
    OrganizationId,
    SegmentCustomFieldFragment
} from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { Filter, PossibleColumn, SegmentService } from '../segment';

export enum EventDefaultColumns {
    Name = 'name',
    StartAt = 'startAt',
    EndAt = 'endAt',
    Tags = 'tags',
    State = 'state',
    Id = 'id'
}

@injectable()
export class EventsSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getEventsFilters(
        organizationId: OrganizationId,
        customFields: SegmentCustomFieldFragment[]
    ): Filter[] {
        return [
            {
                slug: 'name',
                name: this.t('nom_de_l_v_nem_08711'),
                category: this.t('Event'),
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            {
                slug: 'startAt',
                name: this.t('date_de_d_but_43380'),
                category: this.t('Event'),
                fieldType: FilterType.Date,
                hideEmpty: true
            },
            {
                slug: 'endAt',
                name: this.t('date_de_fin_86920'),
                category: this.t('Event'),
                fieldType: FilterType.Date,
                hideEmpty: true
            },
            {
                slug: 'state',
                name: this.t('status_06428'),
                category: this.t('Event'),
                fieldType: FilterType.Select,
                values: [
                    {
                        id: EventState.Ongoing,
                        name: this.t(EventState.Ongoing)
                    },
                    {
                        id: EventState.Upcoming,
                        name: this.t(EventState.Upcoming)
                    },
                    {
                        id: EventState.Done,
                        name: this.t(EventState.Done)
                    }
                ],
                hideEmpty: true
            },
            {
                slug: 'tag',
                name: this.t('Tag'),
                category: this.t('Event'),
                fieldType: FilterType.Select,
                needLoading: true,
                organizationId
            },
            ...this.customFieldsToFilters(customFields)
        ];
    }

    getEventPossibleColumns(customFields: SegmentCustomFieldFragment[]): PossibleColumn[] {
        return (
            [
                {
                    slug: EventDefaultColumns.Id,
                    name: this.t('id_51738'),
                    isSearchable: true,
                    isSortable: true,
                    sortAttribute: EventsSortAttributes.Id,
                    width: 100
                },
                {
                    slug: EventDefaultColumns.Name,
                    name: this.t('nom_de_l_v_nem_08711'),
                    isSearchable: true,
                    isSortable: true,
                    sortAttribute: EventsSortAttributes.Name
                },
                {
                    slug: EventDefaultColumns.StartAt,
                    name: this.t('date_de_d_but_43380'),
                    isSortable: true,
                    sortAttribute: EventsSortAttributes.StartAt
                },
                {
                    slug: EventDefaultColumns.EndAt,
                    name: this.t('date_de_fin_86920'),
                    isSortable: true,
                    sortAttribute: EventsSortAttributes.EndAt
                },
                {
                    slug: EventDefaultColumns.Tags,
                    name: this.t('tags_79499')
                }
            ] as PossibleColumn[]
        ).concat(this.customFieldsToPossibleColumns(customFields));
    }
}
