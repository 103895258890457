import { Flex } from 'common/src/designSystem/components/flex';
import { EventId, OrganizationId } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { Emptyable } from 'common/src/util/emptyable';
import { NominalType } from 'common/src/util/nominalType';
import { isNonEmptyString } from 'common/src/util/string';
import {
    Columns,
    Filter,
    FilterPredicate,
    partitionFiltersPredicates,
    PossibleColumn,
    Predicates
} from 'common/src/vo/segment';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { AllRendering } from '../../../common/src/vo/rendering';
import { OrderRightPanel } from '../designSystem/components/orderRightPanel';
import { useDebounce } from '../hooks/useDebounce';
import { useHeavent } from '../hooks/useHeavent';
import { IUseMassActionsResult, useMassActions } from '../hooks/useMassActions';
import { usePaginationInfos } from '../hooks/usePaginationInfos';
import { Filters } from '../segments/filters/filters';
import { getToken } from '../util/aws/cognito';
import { FiltersColumnsContext } from './filtersColumnsContext';
import { FiltersColumnsHeader } from './filtersColumnsHeader';
import { LoadDataResult, LoadDataResultRows, LoadRowResult } from './filtersColumnsTypes';

interface IFilterColumnsProps<T, TId extends NominalType<number, any>, SortAttribute> {
    children: React.ReactNode;
    columns: Columns;
    columnsSearch: Columns;
    eventId: Emptyable<EventId>;
    filters: Filter[];
    limit: number;
    loadDataListParams?: any;
    loadRowParams?: any;
    organizationId: Emptyable<OrganizationId>;
    possibleColumns: PossibleColumn[];
    possibleRenderings?: AllRendering[];
    predicates: Predicates;
    rendering: AllRendering;
    showEditColumns: boolean;
    showEditMode: boolean;
    showFilters: boolean;
    showMassActions: boolean;
    showRowDropdown: boolean;
    showRowEdit: boolean;
    showSearchColumns: boolean;
    sort: Sort<SortAttribute> | null;

    columnsToIncludes?(columns: Columns, rendering: AllRendering): any;
    getMassActionId?(row: T): number;
    loadDataList(args: any, token: Emptyable<string>): Promise<LoadDataResult<T>>;
    loadRow(args: any, token: Emptyable<string>): Promise<LoadRowResult<T>>;
    renderActions?(
        massActions: IUseMassActionsResult<TId>,
        totalCount: number,
        reload: () => void
    ): React.ReactNode;
    setColumns?(columns: Columns): void;
    setColumnsSearch?(columnsSearch: Columns): void;
    setLimit?(limit: number): void;
    setPredicates?(predicates: Predicates): void;
    setRendering?(rendering: AllRendering): void;
    setSort?(sort: Sort<SortAttribute> | null): void;
}

export const FilterColumns = <
    T extends { id: TId },
    TId extends NominalType<number, any>,
    SortAttribute
>(
    props: IFilterColumnsProps<T, TId, SortAttribute>
) => {
    const { translate } = useHeavent();
    const [predicates, _setPredicates] = React.useState(props.predicates);
    const [columns, _setColumns] = React.useState<Columns>(props.columns);
    const [columnsSearch, _setColumnsSearch] = React.useState<Columns>(props.columnsSearch);
    const [rendering, _setRendering] = React.useState<AllRendering>(props.rendering);
    const [name, setName] = React.useState('');
    const [nameDebounced, _setNameDebounced] = React.useState('');
    const [limit, _setLimit] = React.useState(props.limit);
    const [sort, _setSort] = React.useState<Sort<any> | null>(props.sort);
    const [offset, setOffset] = React.useState(0);
    const setNameDebounced = useDebounce((newName: string) => {
        _setNameDebounced(newName);
        clearCursors();
    });
    const clearCursors = React.useCallback(() => {
        setOffset(0);
    }, [setOffset]);
    const clearNameCursors = React.useCallback(() => {
        setName('');
        _setNameDebounced('');
        clearCursors();
    }, [setName, _setNameDebounced, clearCursors]);
    const setPredicates = React.useCallback(
        (newPredicates: Predicates) => {
            props.setPredicates?.(newPredicates);
            _setPredicates(newPredicates);
        },
        [props.setPredicates, _setPredicates]
    );
    const setColumns = React.useCallback(
        (newColumns: Columns) => {
            props.setColumns?.(newColumns);
            _setColumns(newColumns);
        },
        [props.setColumns, _setColumns]
    );
    const setColumnsSearch = React.useCallback(
        (newColumnsSearch: Columns) => {
            props.setColumnsSearch?.(newColumnsSearch);
            _setColumnsSearch(newColumnsSearch);
        },
        [props.setColumnsSearch, _setColumnsSearch]
    );
    const setRendering = React.useCallback(
        (newRendering: AllRendering) => {
            props.setRendering?.(rendering);
            _setRendering(newRendering);
        },
        [props.setRendering, _setRendering]
    );
    const setLimit = React.useCallback(
        (newLimit: number) => {
            props.setLimit?.(newLimit);
            _setLimit(newLimit);
        },
        [props.setLimit, _setLimit]
    );
    const setSort = React.useCallback(
        (newSort: Sort<any> | null) => {
            props.setSort?.(newSort);
            _setSort(newSort);
            setOffset(0);
        },
        [props.setSort, _setSort, setOffset]
    );
    const initialPredicatesString = React.useMemo(
        () => JSON.stringify(props.predicates),
        [props.predicates]
    );
    const initialColumnsString = React.useMemo(
        () => JSON.stringify(props.columns),
        [props.columns]
    );
    const initialColumnsSearchString = React.useMemo(
        () => JSON.stringify(props.columnsSearch),
        [props.columnsSearch]
    );
    const initalRenderingString = React.useMemo(
        () => JSON.stringify(props.rendering),
        [props.rendering]
    );
    const initialSortString = React.useMemo(() => JSON.stringify(props.sort), [props.sort]);
    const isSaveVisible = React.useMemo(
        () =>
            JSON.stringify(predicates) !== initialPredicatesString ||
            JSON.stringify(columns) !== initialColumnsString ||
            JSON.stringify(columnsSearch) !== initialColumnsSearchString ||
            JSON.stringify(rendering) !== initalRenderingString ||
            JSON.stringify(sort) !== initialSortString,
        [
            initialPredicatesString,
            predicates,
            initialColumnsString,
            columns,
            initialColumnsSearchString,
            columnsSearch,
            initalRenderingString,
            rendering,
            initialSortString,
            sort
        ]
    );
    const includes = React.useMemo(
        () => props.columnsToIncludes?.(columns, rendering) ?? {},
        [columns, rendering, props.columnsToIncludes]
    );
    const [loadDataResult, setLoadDataResult] =
        React.useState<Emptyable<LoadDataResultRows<T>>>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const reload = React.useCallback(async () => {
        setIsLoading(true);
        const result = await props.loadDataList(
            {
                ...includes,
                ...(props.loadDataListParams || {}),
                organizationId: props.organizationId || undefined,
                eventId: props.eventId || undefined,
                name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
                columnsSearch: isNonEmptyArray(columnsSearch) ? columnsSearch : null,
                predicates,
                limit,
                offset,
                sort
            },
            await getToken()
        );
        setLoadDataResult(result.data);
        setIsLoading(false);
    }, [
        columnsSearch,
        includes,
        limit,
        nameDebounced,
        offset,
        predicates,
        setIsLoading,
        setLoadDataResult,
        sort,
        props.organizationId,
        props.eventId,
        props.loadDataList,
        props.loadDataListParams
    ]);
    const [data, setData] = React.useState<T[]>([]);
    const { numberOfPages, totalCount } = usePaginationInfos(loadDataResult?.rows);
    const [idToLoading, setIdToLoading] = React.useState<any>({});
    const reloadRow = React.useCallback(
        async (id: TId) => {
            setIdToLoading((ids: TId[]) => ({ ...ids, [id]: true }));

            const loadRowResult = await props.loadRow(
                {
                    ...includes,
                    ...(props.loadRowParams || {}),
                    organizationId: props.organizationId || undefined,
                    eventId: props.eventId || undefined,
                    id
                },
                await getToken()
            );
            const newRow = loadRowResult.data.row;

            setData((rows) => rows.map((row) => (row.id === id ? newRow : row)));
            setIdToLoading((ids: TId[]) => ({ ...ids, [id]: false }));
        },
        [
            includes,
            setIdToLoading,
            props.organizationId,
            props.eventId,
            props.loadRow,
            props.loadRowParams
        ]
    );
    const getMassActionId = React.useCallback(
        (row: T) => props.getMassActionId?.(row) ?? row.id,
        [props.getMassActionId]
    );
    const massActions = useMassActions({});
    const [filtersPredicates] = React.useMemo(
        () => partitionFiltersPredicates(predicates, props.filters),
        [predicates, props.filters]
    );
    const [isEditMode, setIsEditMode] = React.useState(false);
    const [isFilterOpen, setIsFilterOpen] = React.useState(false);
    const [areColumnsOpen, setAreColumnsOpen] = React.useState(false);
    const [selectedFilterPredicate, setSelectedFilterPredicate] =
        React.useState<FilterPredicate | null>(null);

    React.useEffect(() => {
        reload();
    }, [
        columnsSearch,
        includes,
        limit,
        nameDebounced,
        offset,
        predicates,
        sort,
        props.organizationId,
        props.eventId,
        props.loadDataList
    ]);

    React.useEffect(() => {
        setData(loadDataResult?.rows?.nodes ?? []);
    }, [loadDataResult]);

    React.useEffect(() => {
        massActions.setStates(
            Object.fromEntries(
                data.map((row) => [
                    `r${getMassActionId(row)}`,
                    {
                        id: getMassActionId(row),
                        state: 'unchecked'
                    }
                ])
            )
        );
    }, [data, massActions.setStates]);

    return (
        <FiltersColumnsContext.Provider
            value={{
                areColumnsOpen,
                clearNameCursors,
                columns,
                columnsSearch,
                data,
                filtersPredicates,
                getMassActionId,
                idToLoading,
                isEditMode,
                isFilterOpen,
                isLoading,
                isSaveVisible,
                limit,
                loadDataResult,
                massActions,
                name,
                nameDebounced,
                numberOfPages,
                offset,
                possibleColumns: props.possibleColumns,
                possibleRenderings: props.possibleRenderings,
                predicates,
                reload,
                reloadRow,
                renderActions: props.renderActions,
                rendering,
                selectedFilterPredicate,
                setAreColumnsOpen,
                setColumns,
                setColumnsSearch,
                setIsEditMode,
                setIsFilterOpen,
                setLimit,
                setName,
                setNameDebounced,
                setOffset,
                setPredicates,
                setRendering,
                setSelectedFilterPredicate,
                setSort,
                showEditColumns: props.showEditColumns,
                showEditMode: props.showEditMode,
                showFilters: props.showFilters,
                showMassActions: props.showMassActions,
                showRowDropdown: props.showRowDropdown,
                showRowEdit: props.showRowEdit,
                showSearchColumns: props.showSearchColumns,
                sort,
                totalCount
            }}
        >
            <Flex css={{ background: 'white' }} direction="column" height={1} width={1}>
                <FiltersColumnsHeader />

                {props.children}
            </Flex>

            {isFilterOpen && (
                <Filters
                    possibleFilters={props.filters}
                    predicates={predicates}
                    selectedFilterPredicate={selectedFilterPredicate}
                    onClose={() => {
                        setIsFilterOpen(false);
                    }}
                    onFilter={(newPredicates) => {
                        clearNameCursors();
                        setPredicates(newPredicates);
                    }}
                />
            )}

            {areColumnsOpen && (
                <OrderRightPanel
                    columns={props.possibleColumns}
                    initialSelectedColumns={columns}
                    saveButtonText={translate('_diter_les_colo_39630')}
                    searchPlaceholder={translate('rechercher_un_c_86475')}
                    subtitle={translate('personnaliser_l_03763')}
                    title={translate('_dition_des_col_70300')}
                    onClose={() => {
                        setAreColumnsOpen(false);
                    }}
                    onSave={setColumns}
                />
            )}
        </FiltersColumnsContext.Provider>
    );
};
