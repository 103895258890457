import {
    AccreditationId,
    AccreditationsCategoryId,
    AccreditationsSlotId,
    AccreditationsSlotsSortAttributes,
    DelegationId,
    EventId,
    FormId,
    OrganizationId,
    PositionId,
    PositionsCategoryId,
    PositionsSlotsSortAttributes,
    SegmentId,
    SegmentsFolderId,
    SortDirection,
    TagId,
    UsersInfosSortAttributes,
    VolunteerRegistrationState,
    VolunteersRegistrationsSortAttributes
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { IGoogleCoordinates } from 'common/src/util/map';
import { Predicates } from 'common/src/vo/segment';
import { Sort } from 'common/src/vo/sort';
import Dexie, { Table } from 'dexie';
import { DateTime } from 'luxon';
import { AccreditationState } from './components/accreditations/rightPanel/accreditationsRightPanelContext';

export type AcronymIcon = 'acronym' | 'icon';

export interface LocalEvent {
    id: EventId;
    'accreditation.slots.date'?: DateTime | null;
    'accreditation.slots.sort'?: Sort<AccreditationsSlotsSortAttributes> | null;
    accreditationsLimit?: number;
    accreditationsSegmentId?: Emptyable<SegmentId>;
    'accreditationMembers.columns'?: string[];
    'accreditationMembers.limit'?: number;
    'accreditationMembers.predicates'?: Predicates;
    'accreditationMembers.sort'?: Sort<VolunteersRegistrationsSortAttributes> | null;
    areAccreditationsSegmentsOpen?: boolean;
    areDelegationsSegmentsOpen?: boolean;
    arePositionsSegmentsOpen?: boolean;
    areVolunteersSegmentsOpen?: boolean;
    'assignment.isLeftPanelOpen'?: boolean;
    'assignment.isRightPanelOpen'?: boolean;
    'assignment.leftPanelDirection'?: SortDirection;
    'assignment.leftPanelSegmentId'?: SegmentId;
    'assignment.leftPanelStates'?: VolunteerRegistrationState[];
    'assignment.filters.positionsCategoriesIds'?: PositionsCategoryId[] | null;
    'assignment.filters.positionsIds'?: PositionId[] | null;
    'assignment.filters.date'?: DateTime | null;
    'assignment.filters.tagsIds'?: TagId[] | null;
    'assignment.filters.segmentId'?: SegmentId | null;
    'assignment.filters.excludeFull'?: boolean;
    'assignment.rightPanel.areInformationsOpen'?: boolean;
    'assignment.rightPanel.areAvailabilitiesOpen'?: boolean;
    'assignment.rightPanel.arePositionsOpen'?: boolean;
    'assignment.rightPanel.areAccreditationsOpen'?: boolean;
    'accredit.isLeftPanelOpen'?: boolean;
    'accredit.isRightPanelOpen'?: boolean;
    'accredit.leftPanelDirection'?: SortDirection;
    'accredit.leftPanelSegmentId'?: SegmentId;
    'accredit.leftPanelDelegationId'?: DelegationId;
    'accredit.leftPanelStates'?: AccreditationState[];
    'accredit.filters.accreditationsCategoriesIds'?: AccreditationsCategoryId[] | null;
    'accredit.filters.accreditationsIds'?: AccreditationId[] | null;
    'accredit.filters.date'?: DateTime | null;
    'accredit.filters.tagsIds'?: TagId[] | null;
    'accredit.filters.segmentId'?: SegmentId | null;
    'accredit.filters.excludeFull'?: boolean;
    delegationsLimit?: number;
    delegationsSegmentId?: Emptyable<SegmentId>;
    isPositionsMapAlertVisible?: boolean;
    'massAssign.filters.excludeFull'?: boolean;
    'massAssign.filters.filterAvailabilities'?: boolean;
    'massAssign.filters.filterWished'?: boolean;
    'massAssign.filters.segmentId'?: SegmentId | null;
    'position.slots.date'?: DateTime | null;
    'position.slots.sort'?: Sort<PositionsSlotsSortAttributes> | null;
    positionBackPath?: string;
    positionCategoryBackPath?: string;
    'positionsMap.center'?: IGoogleCoordinates;
    'positionsMap.zoom'?: number;
    'positionsMap.acronymIcon'?: AcronymIcon;
    'positionMembers.columns'?: string[];
    'positionMembers.limit'?: number;
    'positionMembers.predicates'?: Predicates;
    'positionMembers.sort'?: Sort<VolunteersRegistrationsSortAttributes> | null;
    positionsLimit?: number;
    positionsSegmentId?: Emptyable<SegmentId>;
    'positions.planning.startAt'?: DateTime;
    'positionsSlotsAssignVolunteers.applyConditions'?: boolean;
    'positionsSlotsAssignVolunteers.filterAvailabilities'?: boolean;
    'positionsSlotsAssignVolunteers.filterPositions'?: boolean;
    showNotFilled?: boolean;
    'user.accreditations.display'?: string;
    'user.positions.display'?: string;
    volunteersLimit?: number;
    volunteersSegmentId?: Emptyable<SegmentId>;
    'volunteers.planning.startAt'?: DateTime;
    'volunteers.matrix.selectedSlotsIds'?: AccreditationsSlotId[];
}

interface LocalForm {
    id: FormId | 'all';
    isOpenInUserOverlay: boolean;
}

export interface LocalOrganization {
    id: OrganizationId;
    areCampaignsSegmentsOpen?: boolean;
    areCustomFieldsSegmentsOpen?: boolean;
    areDelegationsSegmentsOpen?: boolean;
    areEventsSegmentsOpen?: boolean;
    areUsersSegmentsOpen?: boolean;
    areParametersMenuSectionsOpen?: { [name: string]: boolean };
    campaignsLimit?: number;
    campaignsSegmentId?: Emptyable<SegmentId>;
    customFieldsLimit?: number;
    customFieldsSegmentId?: Emptyable<SegmentId>;
    'delegationCategoryMembers.columns'?: string[];
    'delegationCategoryMembers.limit'?: number;
    'delegationCategoryMembers.predicates'?: Predicates;
    'delegationCategoryMembers.sort'?: Sort<UsersInfosSortAttributes> | null;
    delegationsLimit?: number;
    delegationsSegmentId?: Emptyable<SegmentId>;
    duplicatesColumns?: string[];
    'eventDelegationMembers.columns'?: string[];
    'eventDelegationMembers.limit'?: number;
    'eventDelegationMembers.predicates'?: Predicates;
    'eventDelegationMembers.sort'?: Sort<VolunteersRegistrationsSortAttributes> | null;
    eventsSegmentId?: Emptyable<SegmentId>;
    eventsLimit?: number;
    isEventMenuOpen?: boolean;
    'organizationDelegationMembers.columns'?: string[];
    'organizationDelegationMembers.limit'?: number;
    'organizationDelegationMembers.predicates'?: Predicates;
    'organizationDelegationMembers.sort'?: Sort<UsersInfosSortAttributes> | null;
    showNotFilled?: boolean;
    usersLimit?: number;
    usersSegmentId?: Emptyable<SegmentId>;
}

interface LocalSegmentFolder {
    id: SegmentsFolderId;
    isOpen?: boolean;
}

class DexieRecrewteer extends Dexie {
    events!: Table<LocalEvent>;
    forms!: Table<LocalForm>;
    organizations!: Table<LocalOrganization>;
    segmentsFolders!: Table<LocalSegmentFolder>;

    constructor() {
        super('recrewteer');

        this.version(6).stores({
            events: 'id',
            forms: 'id',
            organizations: 'id',
            segmentsFolders: 'id'
        });
    }
}

export const localDatabase = new DexieRecrewteer();
